import React, { Component } from 'react';
import './About.css';
import { StateContext } from '../Store/state-context';

import group from '../../assets/images/group.png';
import lendifyUser from '../../assets/images/lendify_user.svg';
import invertTop from '../../assets/images/invert_top.png';
import invertDown from '../../assets/images/invert_down.png';
import lendify from '../../assets/images/lendify.svg';
import transferGalaxy from '../../assets/images/transfer_galaxy.svg';

import transferGalaxyUser from '../../assets/images/transfer_galaxy_user.svg';
import paperplan from '../../assets/images/Plaper_Plan.png';
import saldo from '../../assets/images/saldo.svg';
import saldoUser from '../../assets/images/saldo_user.svg';

import teamMember01 from '../../assets/images/team_members/roni.png';
import teamMember02 from '../../assets/images/team_members/arlen.png';
import teamMember03 from '../../assets/images/team_members/magnus.png';
import teamMember04 from '../../assets/images/team_members/team_member04.svg';
import teamMember05 from '../../assets/images/team_members/lorenzo.png';
import teamMember06 from '../../assets/images/team_members/andres.png';
import teamMember07 from '../../assets/images/team_members/johan.png';
import teamMember08 from '../../assets/images/team_members/parang.png';
import teamMember09 from '../../assets/images/team_members/bhavesh.png';
import teamMember10 from '../../assets/images/team_members/yogesh.png';
import teamMember11 from '../../assets/images/team_members/team_member_11.png';
import teamMember12 from '../../assets/images/team_members/team_member12.svg';
import teamMember13 from '../../assets/images/team_members/team_member13.png';
import teamMember14 from '../../assets/images/team_members/team_member_14.png';
import teamMember15 from '../../assets/images/team_members/wictor.png';
import teamMember17 from '../../assets/images/team_members/team_member17.png';
import teamMember18 from '../../assets/images/team_members/team_member18.png';
import teamMember19 from '../../assets/images/team_members/team_member19.png';
import teamMember20 from '../../assets/images/team_members/team_member20.png';
import teamMember21 from '../../assets/images/team_members/team_member21.png';
import teamMember22 from '../../assets/images/team_members/team_member22.png';
import teamMember25 from '../../assets/images/team_members/team_member25.png';
import teamMember26 from '../../assets/images/team_members/team_member26.png';
import teamMember27 from '../../assets/images/team_members/alex.png';
import teamMember28 from '../../assets/images/team_members/kim.png';
import ludvikImg from '../../assets/images/team_members/Ludvig-Dickman-VP-Engineering.png'
import ebba from '../../assets/images/team_members/ebba.png'
import angelynn from '../../assets/images/team_members/angelynn.png'
import elias from '../../assets/images/team_members/elias.png'
import fanny from '../../assets/images/team_members/fanny.png'
import richard from '../../assets/images/team_members/richard.png'
import rimante from '../../assets/images/team_members/rimante.png'









import boardMember01 from '../../assets/images/board_members/board_member01.png';
import boardMember03 from '../../assets/images/board_members/board_member_03.png';
import boardMember05 from '../../assets/images/board_members/board_member_05.png';
import boardMember06 from '../../assets/images/board_members/magnus.png';
import boardMember07 from '../../assets/images/board_members/roni.png';
import staffan from '../../assets/images/board_members/staffan.png'
import maarten from '../../assets/images/board_members/maarten.png'
import erik from '../../assets/images/board_members/erik.png'



import customers01 from '../../assets/images/customers/customers01.svg';
import customers02 from '../../assets/images/customers/customers02.svg';
import customers03 from '../../assets/images/customers/customers03.svg';
import customers04 from '../../assets/images/customers/customers04.svg';
import customers05 from '../../assets/images/customers/customers05.svg';
import customers06 from '../../assets/images/customers/customers06.svg';
import customers07 from '../../assets/images/customers/customers07.svg';
import customers08 from '../../assets/images/customers/customers08.svg';


import investor1 from '../../assets/images/Salenia_logo.png';
import investor2 from '../../assets/images/Segula_logo.png';

class About extends Component{

  static contextType = StateContext;

  render(){
    let bannerPadding = "";
 
    if(this.context.isBanner === true){
      bannerPadding = "banner-padding-about"
    }
    else{
      bannerPadding = ""
    }
    return(
      <div className="main-section main-body-bg">
        <div className="banner-text pb-0" id={'about-kreditz'}>
          <div className={`width-1200 ${bannerPadding}`}>
						<div className="row">
							<div className="col-md-12">
								<h1 className="h1-basic01">Our mission statement<br/>is simple:<span>One world,<br/>One score.</span></h1>
								<div className="row mt-40">
									<div className="col-md-7">
										<h6 className="h6-basic03">Open banking and PSD2 will help to bring financial transparency and responsibility across the globe. By using bank transaction data as a foundation, Kreditz helps businesses to interact with consumers in a financially transparent environment in order to make better decisions.<br/><br/>Kreditz believes in a borderless world with financial inclusion for everyone. That’s why we keep working toward the vision of creating a global credit score – One world. One score.</h6>
									</div>
								</div>
								<div className="row custom-insights group-values">
									<div className="col-md-6">
										<ul>
											<li><h2>2018</h2>Founding year</li>
											<li><h2>30+</h2>Employees</li>
											<li><h2>40+</h2>Clients</li>
											<li><h2>500m+</h2>Transactions analyzed</li>
										</ul>
									</div>
									<div className="col-md-6">
										<figure><img src={group} alt="group" width="100%"/></figure>
									</div>
								</div>
								<div className="row group-values hide">
									<div className="col-md-6">
										<h2 className="h2-basic01">Why we started<br/>Kreditz?</h2>
										<h6 className="h6-basic01">Kreditz wants to help both consumers and businesses to make better and safer transactions. By using bank transaction data as a foundation, Kreditz help you as a consumer to confirm or request ability to pay during transactions with other consumers, while at the same time businesses can use Kreditz to offer you as a consumer better deals an terms when selling their products and services.<br/><br/>Kreditz believes in a borderless world with financial inclusion for everyone. That’s why we keep working toward the vision of creating a global credit score – One world. One score.</h6>
									</div>
									<div className="col-md-6">
										<ul className="start-kreditz">
											<li><h2>270</h2>Kreditz employees</li>
											<li><h2>40+</h2>Nationalities represented</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="build-team">
					<div className="width-1200">
						<div className="row">
							<div className="col-md-6">
								<h2 className="h2-basic01">A team with<br/>passionate people</h2>
								<h6 className="h6-basic01">Kreditz was founded by, and employs, people with an entrepreneurial spirit and vast experience from financial services, financial technology and credit scoring.</h6>
							</div>
						</div>
					</div>
				</div>
				<div id={'team-members'}>
					<div className="staff-members">
						<div className="width-1200">
							<p className="staff-heading">Staff members</p>
							<ul>
								<li><img src={teamMember01} alt="teamMember01"/><p>Roni Zacharion</p><span>CEO & Founder</span></li>
								<li><img src={teamMember02} alt="teamMember02"/><p>Arlen Rios</p><span>CTO & Co-founder</span></li>
								<li><img src={teamMember03} alt="teamMember03"/><p>Magnus Källhager</p><span>CAO & Co-founder</span></li>
								<li><img src={teamMember26} alt="teamMember26"/><p>Christoffer Cederschiöld</p><span>Deputy CEO & CCO</span></li>
								<li><img src={teamMember05} alt="teamMember05"/><p>Lorenzo Puccio</p><span>Chief Operating Officer</span></li>
								<li><img src={richard} alt="richard"/><p>Richard Wachtmeister</p><span>Chief Legal Officer</span></li>
								<li><img src={elias} alt="teamMember04"/><p>Elias Abrahamsson</p><span>Director of Sales & Partnerships</span></li>
								<li><img src={ludvikImg} alt="ludvikImg"/><p>Ludvig Dickman</p><span>VP of Engineering</span></li>
								<li><img src={teamMember06} alt="teamMember06"/><p>Anders Gustavsson</p><span>QA Engineering Manager</span></li>
								<li><img src={teamMember07} alt="teamMember07"/><p>Johan Walberg</p><span>QA Engineering Manager</span></li>
								<li><img src={teamMember08} alt="teamMember08"/><p>Parang Laleh</p><span>Project Lead & Q/A Engineer</span></li>
								<li><img src={fanny} alt="fanny"/><p>Fanny Philipsson</p><span>Fullstack Developer</span></li>
								<li><img src={rimante} alt="rimante"/><p>Rimante Awdisson</p><span>Fullstack Developer</span></li>
								<li><img src={teamMember14} alt="teamMember14"/><p>Bob Ngoga Kirabo</p><span>Lead Data Scientist</span></li>
								<li><img src={teamMember15} alt="teamMember15"/><p>Wictor Johansson</p><span>Data Scientist</span></li>
								<li><img src={ebba} alt="ebba"/><p>Ebba Blom</p><span>Junior Data Scientist</span></li>
								<li><img src={teamMember27} alt="teamMember27"/><p>Alex Wahlberg</p><span>Customer Success Manager/Project Lead</span></li>
								<li><img src={teamMember28} alt="teamMember28"/><p>Kim Ekberg</p><span>Head of Sales Enablement</span></li>
								<li><img src={angelynn} alt="angelynn"/><p>Angelynn Santiago</p><span>Business Development Representative</span></li>
								<li><img src={teamMember09} alt="teamMember09"/><p>Bhavesh Kshatriya</p><span>VP of Engineering</span></li>
								<li><img src={teamMember10} alt="teamMember10"/><p>Yogesh Patidar</p><span>Sr. Software Engineer</span></li>
								<li><img src={teamMember11} alt="teamMember11"/><p>Jayesh Dateer</p><span>Data Engineer</span></li>
								<li><img src={teamMember12} alt="teamMember12"/><p>Shailendra Singh</p><span>Sr. Software Engineer</span></li>
								<li><img src={teamMember13} alt="teamMember13"/><p>Sanjay Vishwakarma</p><span>iOS Developer</span></li>
								<li><img src={teamMember20} alt="teamMember20"/><p>Poonam Patel</p><span>Software Engineer</span></li>
								<li><img src={teamMember22} alt="teamMember22"/><p>Jalaj Gupta</p><span>Software Engineer</span></li>
								<li><img src={teamMember21} alt="teamMember21"/><p>Chandan Jachpure</p><span>UX Engineer</span></li>
								<li><img src={teamMember25} alt="teamMember25"/><p>Hariom Meena</p><span>UX Designer</span></li>
								<li><img src={teamMember18} alt="teamMember18"/><p>Sonali Diwakar</p><span>1st Line Support Engineer</span></li>
								<li><img src={teamMember17} alt="teamMember17"/><p>Rajani Kumar</p><span>Dev-ops Engineer</span></li>
								<li><img src={teamMember19} alt="teamMember19"/><p>Khushbu Vijayvargiya</p><span>Dev-ops Engineer</span></li>
							</ul>
						</div>
					</div>
				</div>
				<div id={'board-members'}>
					<div className="staff-members">
						<div className="width-1200">
							<p className="staff-heading">Board members</p>
							<ul>
								<li><img src={boardMember01} alt="boardMember01"/><p>Anna Storåkers</p><span>Chairwoman</span></li>
								<li><img src={boardMember03} alt="boardMember03"/><p>Anders Hugosson</p><span>Board member</span></li>
								<li><img src={boardMember05} alt="boardMember05"/><p>Joel Urwitz</p><span>Board member</span></li>
								<li><img src={staffan} alt="boardMember05"/><p>Staffan Salén</p><span>Board member</span></li>
								<li><img src={erik} alt="boardMember05"/><p>Erik Törnberg</p><span>Board member</span></li>
								<li><img src={maarten} alt="boardMember05"/><p>Maarten Grosfeld</p><span>Board member</span></li>
								<li><img src={boardMember06} alt="boardMember06"/><p>Magnus Källhager</p><span>Board member</span></li>
								<li><img src={boardMember07} alt="boardMember07"/><p>Roni Zacharion</p><span>Board member</span></li>
							</ul>
						</div>
					</div>
				</div>
				<div id={'investors'}>
					<div className="staff-members">
						<div className="width-1200">
							<p className="staff-heading">Some of our investors</p>
							<ul className="investor-list">
								<li><figure><img src={investor1} alt="investor1"/></figure><p>Salénia Invest</p></li>
								<li><figure><img src={investor2} alt="investor2"/></figure><p>Segulah Venture</p></li>
							</ul>
						</div>
					</div>
				</div>
				<div className="build-team hide">
					<div className="width-1200">
						<div className="row">
							<div className="col-md-6">
								<h2 className="h2-basic01">Some of our customers<br/>who trust us...</h2>
								<h6 className="h6-basic01">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Id urna amet, vulputate neque imperdiet molestie arcu urna odio.</h6>
							</div>
						</div>
					</div>
				</div>
				<div className="customers-list hide">
					<div className="width-1200">
						<ul>
							<li><img src={customers01} alt="customers01"/></li>
							<li><img src={customers02} alt="customers02"/></li>
							<li><img src={customers03} alt="customers03"/></li>
							<li><img src={customers04} alt="customers04"/></li>
							<li><img src={customers05} alt="customers05"/></li>
							<li><img src={customers06} alt="customers06"/></li>
							<li><img src={customers07} alt="customers07"/></li>
							<li><img src={customers08} alt="customers08"/></li>
						</ul>
					</div>
				</div>
				<div className="testimonials-set hide" id={'clients'}>
					<div className="width-1200">
						<div className="row">
							<div className="col-md-12">
								<h2 className="h2-basic01">Hear what some of our<br/>clients think</h2>
							</div>
						</div>
						<div className="slider single-slide-testimonials">
						  <div className="slide testimonials-slides">
						  	<figure className="clients-image">
						  		<img src={lendifyUser} alt="lendifyUser" />
						  	</figure>
						  	<div className="clients-review">
						  		<img className="review-invert" src={invertTop} alt="invertTop"/>
						  		<div className="logo-description">
						  			<div>Lendify is one of the leading challengers in the lending space in the Nordics. As a challenger and pioneer, the use of PSD2-data has been crucial in order to gain that extra insight and an advantage over traditional lenders. Kreditz helps Lendify to keep that advantage with fine-tuned categorization and a simple technical solution.<img src={invertDown} alt="invertDown"/></div>
						  			<p>Rebecca Young, Director of board and content marketing</p>
						  			<figure><img src={lendify} alt="lendify"/></figure>
						  		</div>
						  	</div>
						  </div>
						  <div className="slide testimonials-slides testimonials-slides-right">
						  	<div className="clients-review">
                  <img className="review-invert" src={invertTop} alt="invertTop"/>
						  		<div className="logo-description">
						  			<div>TransferGalaxy, one of the most exciting FinTech's from Sweden, is bringing international money transfer to the 21st  century by helping people send funds to friends and family in other countries both fast and affordable – A great service indeed. Kreditz helps TransferGalaxy stay on top of KYC/AML-issues by screening the transactions<img src={invertDown} alt="invertDown"/></div>
						  			<p>John Abras, Director of board and content marketing</p>
						  			<figure><img src={transferGalaxy} alt="transferGalaxy" /></figure>
						  		</div>
						  	</div>
						  	<figure className="clients-image">
						  		<img src={transferGalaxyUser} alt="transferGalaxyUser"/>
						  	</figure>
						  </div>
						  <div className="slide testimonials-slides">
						  	<figure className="clients-image">
						  		<img src={saldoUser} alt="saldoUser"/>
						  	</figure>
						  	<div className="clients-review">
                  <img className="review-invert" src={invertTop} alt="invertTop"/>
						  		<div className="logo-description">
						  			<div>Saldo, the Finnish innovative lender, has been using transaction data in their credit decisioning for some time now, which has helped them to detect both more opportunities and sort out the potential risks at an earlier stage - this is the next generation credit process in it’s true form and it works great. Kreditz helps Saldo’s credit scoring process to stay lightyears ahead of the competition.<img src={invertDown} alt="invertDown"/></div>
						  			<p>Robin Martin, Director of board and content marketing</p>
						  			<figure><img src={saldo} alt="saldo"/></figure>
						  		</div>
						  	</div>
						  </div>
						</div>
					</div>
				</div>
				<div className="careeer">
					<div className="width-1200">
						<div className="row">
							<div className="col-md-5">
								<h2 className="h2-basic01">Career</h2>
								<h6 className="h6-basic01">Learn more about our company, our history, our mission, and find out what drives us.</h6>
								<a href="https://careers.kreditz.com/" className="know-more" target="_blank" rel="noopener noreferrer">Know more about available possitions</a>
							</div>
							<div className="col-md-7">
								<img src={paperplan} alt="paperplan"/>
							</div>
						</div>
					</div>
				</div>
      </div>
    );
  }
}
export default About;